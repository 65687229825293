import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router, RouterModule,} from "@angular/router";
import {IconsComponent} from "../../uikit/icons/icons.component";
import {CommonModule} from "@angular/common";
import {StorageService} from "../../service/storage.service";
import {LogoComponent} from "../../uikit/logo/logo.component";
import {MessagesApiService} from "../../api/ws.service";
import {UserRepositoryService} from "../../repository/user-repository.service";
import {Subscription} from 'rxjs';
import {NotificationsRepositoryService} from "../../repository/notifications-repository.service";
import { UsersSubnavigationComponent } from './users-subnavigation/users-subnavigation.component';
import { User } from 'src/app/model/user';
import { RoutingService } from 'src/app/service/routing.service';
import { PostsService } from 'src/app/service/posts.service';
import { NavigationTemplatePipe } from 'src/app/pipes/navigation-template.pipe';
import {ProfileAvatarComponent} from "../../uikit/profile/profile-avatar/profile-avatar.component";
import {ContextMenuModule} from "@perfectmemory/ngx-contextmenu";
import {UserPopupComponent} from "../user-popup/user-popup.component";
import {EUserImageType} from "@prestinly/core-lib/lib/enums/user-image-type.enum";
import {ENotificationType} from "@prestinly/core-lib/lib/enums/notifications/notification-type.enum";
import {EventsTrackerService} from "../../service/events-tracker.service";
import {TranslocoModule} from "@ngneat/transloco";

export enum EMenuItem {
  home = 'home',
  messages = 'messages',
  notifications = 'notifications',
  profile = 'profile',
  statements = 'statements',
  content = 'content',
  queue = 'queue',
  statistics = 'statistics',
  settings = 'settings',
  post = 'post',
  creator = 'creator',
  login = 'login',
  boost = 'boost',
}

export interface MenuItem {
  type: EMenuItem;
  name: string;
  routerLink?: string | undefined;
  action?: any;
  counter: number;
  icon: MenuIcon;
  iconMobile?: MenuIcon | any | undefined;
  isPublic?: boolean | undefined;
}

export interface MenuIcon {
  name: string;
  type: string;
  width?: number;
  height?: number;
}

@Component({
  selector: 'app-navigator',
  standalone: true,
  imports: [
    RouterModule,
    IconsComponent,
    LogoComponent,
    CommonModule,
    UsersSubnavigationComponent,
    NavigationTemplatePipe,
    ProfileAvatarComponent,
    ContextMenuModule,
    UserPopupComponent,
    TranslocoModule,
  ],
  templateUrl: './navigator.component.html',
  styleUrl: './navigator.component.scss'
})
export class NavigatorComponent implements OnInit, OnDestroy {
  readonly EMenuItem = EMenuItem;
  private userDataSub$: Subscription;
  private unreadMessagesSub$: Subscription;
  private unreadNotificationsSub$: Subscription;
  public isContentCreator = false;
  public isSubmenuVisible = false;

  @ViewChild('desktopRegular', { static: true }) desktopRegular: TemplateRef<any>;
  @ViewChild('desktopCreator', { static: true }) desktopCreator: TemplateRef<any>;
  @ViewChild('desktopPost', { static: true }) desktopPost: TemplateRef<any>;
  @ViewChild('desktopBoost', { static: true }) boostTemplate: TemplateRef<any>;
  @ViewChild('loginFef', { static: true }) loginFef: TemplateRef<any>;

  @ViewChild('mobileRegular', { static: true }) mobileRegular: TemplateRef<any>;
  @ViewChild('mobileCreator', { static: true }) mobileCreator: TemplateRef<any>;
  @ViewChild('mobileSettings', { static: true }) mobileSettings: TemplateRef<any>;
  @ViewChild('mobilePost', { static: true }) mobilePost: TemplateRef<any>;

  templates = {};

  home: MenuItem = {
    type: EMenuItem.home,
    name: 'menu_Home',
    routerLink: '/home',
    counter: 0,
    icon: {name: 'home', type: 'icons',},
    isPublic: true,
  };
  messages: MenuItem = {
    type: EMenuItem.messages,
    name: 'menu_Messages',
    routerLink: '/messages',
    counter: 0,
    icon: {name: 'messages', type: 'icons',}
  };
  notifications: MenuItem = {
    type: EMenuItem.notifications,
    name: 'menu_Notifications',
    routerLink: '/notifications',
    counter: 0,
    icon: {name: 'notifications', type: 'icons',}
  };
  profile: MenuItem = {
    type: EMenuItem.profile,
    name: 'menu_My profile',
    routerLink: '/my-profile',
    counter: 0,
    icon: {name: 'profile', type: 'icons',}
  };
  statements: MenuItem = {
    type: EMenuItem.statements,
    name: 'menu_Statements',
    routerLink: '/statements',
    counter: 0,
    icon: {name: 'statements', type: 'icons',}
  };
  content: MenuItem = {
    type: EMenuItem.content,
    name: 'menu_Content',
    routerLink: '/content',
    counter: 0,
    icon: {name: 'content', type: 'icons',}
  };
  queue: MenuItem = {
    type: EMenuItem.queue,
    name: 'Queue',
    routerLink: '/queue',
    counter: 0,
    icon: {name: 'calendar_2', type: 'icons',}
  };
  statistics: MenuItem = {
    type: EMenuItem.statistics,
    name: 'menu_Statistics',
    routerLink: '/statistics',
    counter: 0,
    icon: {name: 'statistic', type: 'icons',}
  };
  settings: MenuItem = {
    type: EMenuItem.settings,
    name: 'menu_Settings',
    routerLink: '/settings',
    counter: 0,
    icon: {name: 'settings', type: 'icons',}
  };
  post: MenuItem = {
    type: EMenuItem.post,
    name: 'menu_New post',
    action: this.newPostPress,
    counter: 0,
    icon: {name: 'plus', type: 'icons',}
  };
  creator: MenuItem = {
    type: EMenuItem.creator,
    name: 'menu_Become_a_Creator',
    action: this.becomeCreatorPress,
    counter: 0,
    icon: {name: 'fire', type: 'icons',}
  };
  login: MenuItem = {
    type: EMenuItem.login,
    name: 'menu_Login',
    routerLink: '/signin',
    counter: 0,
    icon: {name: 'logo', type: 'icons',}
  };
  boost: MenuItem = {
    type: EMenuItem.boost,
    name: 'menu_Boost_your_Account',
    routerLink: 'https://agency.desiren.me',
    counter: 0,
    icon: {name: 'boost', type: 'icons',}
  };

  isContentEnabled = true;
  avatarUrl: string | undefined;
  mockAvatar: string = '../../../assets/png/avatar.png';
  user: User | null;

  get publicMenu() {
    return [this.home, this.creator, this.login]
  }

  get desktopMenu() {
    if (this.isContentCreator) {
      return [
        this.home,
        this.notifications,
        this.messages,
        this.queue,
        this.statements,
        this.content,
        this.creator,
        this.post,
        this.boost
      ];
    }
    return [
      this.home,
      this.messages,
      this.notifications,
      this.creator,
      this.post,
    ];
  };

  get mobileMenu() {
    if (this.isContentCreator) {
      return [
        this.home,
        this.notifications,
        this.post,
        this.messages,
        this.settings,
      ];
    }
    return [
      this.home,
      this.notifications,
      this.post,
      this.messages,
      this.settings,
    ];
  };

  updateCount(prop: string, count: number): void {
    switch (prop) {
      case 'messages':
        this.messages.counter = count;
        break;
      case 'notifications':
        this.notifications.counter = count;
        break;
      default:
        break;
    }
    this._crd.detectChanges();
  }

  disableContent() {
    this.isContentEnabled = false;
  }

  menuItems: Array<MenuItem>;
  mobileMenuItems: Array<MenuItem>;

  constructor(
    private storage: StorageService,
    private userRepo: UserRepositoryService,
    private router: Router,
    private socket: MessagesApiService,
    private _crd: ChangeDetectorRef,
    private notification: NotificationsRepositoryService,
    private routesService: RoutingService,
    private postService: PostsService,
    private trackerService: EventsTrackerService,
  ) {
  }

  ngOnInit() {
    this.templates = {
      regular: this.desktopRegular,
      creator: this.desktopCreator,
      post: this.desktopPost,
      boost: this.boostTemplate,
      login: this.loginFef,
      mobileRegular: this.mobileRegular,
      mobileCreator: this.mobileCreator,
      mobileSettings: this.mobileSettings,
      mobilePost: this.mobilePost,
    };

    this.unreadMessagesSub$ = this.socket.chatList$.subscribe(_ => {
      this.updateCount('messages', this.socket.unreadMessagesCount);
    });
    this.isContentCreator = (this.isLoggedIn && this.userRepo.meLazy().cache.isCreator) || false;

    this.userDataSub$ = this.userRepo.meSubject$
      .subscribe(data => {
        this.user = data;
        this.isContentCreator = this.user?.isCreator ?? false;
        if (!this.isContentCreator) {
          this.disableContent();
        }
        this.avatarUrl = data?.images?.find(img => img.imageType === EUserImageType.AVATAR)?.url || this.mockAvatar;
        this.setMenuItems();
        this.setMobileMenu();
        this._crd.detectChanges();
      });
    this.unreadNotificationsSub$ = this.notification.unreadSubject$.subscribe(data => {
      if (data == null) return;
      this.updateCount('notifications', [
        data[ENotificationType.CONTENT],
        data[ENotificationType.PURCHASES],
        data[ENotificationType.SUBSCRIPTION],
        data[ENotificationType.SYSTEM],
        data[ENotificationType.TAGS]
      ].reduce((a, b) => a + b));
    });
  }

  get isLoggedIn() {
    return this.userRepo.isLoggedIn;
  }

  setMenuItems() {
    this.menuItems = this.isLoggedIn ? this.desktopMenu : this.publicMenu;
  }

  setMobileMenu() {
    this.mobileMenuItems = this.isLoggedIn ? this.mobileMenu : [];
  }

  newPostPress() {
    this.trackerService.newPost(this.isLoggedIn, this.isContentCreator, this.user?.id);
    if (!this.isContentCreator) {
      return this.becomeCreatorPress(false);
    }
    return this.router.navigate(['/my-profile/new-post']);
  }

  becomeCreatorPress(track: boolean = true) {
    if (track) {
      this.trackerService.becomeACreatorPress(this.isLoggedIn, this.user?.id);
    }
    if (!this.isLoggedIn) {
      return this.router.navigate(['/signup'], {
        queryParams: {
          returnUrl: this.router.url
        }
      });
    }
    return this.router.navigate(['/my-profile/become-a-creator']);
  }

  ngOnDestroy(): void {
    this.userDataSub$.unsubscribe();
    this.unreadMessagesSub$.unsubscribe();
    this.unreadNotificationsSub$.unsubscribe();
  }

  handleSubmenuClick(event: MouseEvent): void {
    if (!this.isLoggedIn) {
       this.router.navigate(['/signup'], {
         queryParams: {
           returnUrl: this.router.url
         }
       });
    }
    const target = event.target as HTMLElement;
    if (target.closest('a') && target.closest('a')!.id !== 'settings-mob-btn') {
      this.isSubmenuVisible = false;
    } else if (target.closest('a')!.id === 'settings-mob-btn') {
      this.isSubmenuVisible = !this.isSubmenuVisible;
    }
  }

  handleMenuItemEvent(e: Event) {
    const target = e.target as HTMLElement;
    const anchorElement = target.closest('a.navigation-list-item');
    this.routesService.currentUrl = '/';
    this.routesService.setPreviousUrl(this.routesService.prevUrl);
    if (anchorElement.id === this.home.name) {
      this.postService.resetPostSettings();
      this.postService.clearPosts();
    }
  }

  loginFn(route?: string) {
    this.router.navigate([route ?? '/signup'], {
      queryParams: {
        returnUrl: this.router.url
      }
    })
  }
}
