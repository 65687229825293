import { Pipe, PipeTransform } from '@angular/core';
import { EMenuItem, MenuItem } from '../components/navigator/navigator.component';

@Pipe({
  name: 'navigationTemplate',
  standalone: true
})
export class NavigationTemplatePipe implements PipeTransform {

  transform(item: MenuItem, templates: any, isMobile: boolean = false): any {
    if (isMobile) {
      switch (item.type) {
        case EMenuItem.creator:
          return templates.mobileCreator;
        case EMenuItem.post:
          return templates.mobilePost;
        case EMenuItem.settings:
          return templates.mobileSettings;
        case EMenuItem.boost:
          return templates.mobileBoost;
        case EMenuItem.login:
          return templates.mobileLogin;
        default:
          return templates.mobileRegular;
      }
    } else {
      switch (item.type) {
        case EMenuItem.creator:
          return templates.creator;
        case EMenuItem.post:
          return templates.post;
        case EMenuItem.boost:
          return templates.boost;
        case EMenuItem.login:
          return templates.login;
        default:
          return templates.regular;
      }
    }
  }
}
