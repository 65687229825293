import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import {User} from "../../model/user";
import {ProfileAvatarComponent} from "../../uikit/profile/profile-avatar/profile-avatar.component";
import {IconsComponent} from "../../uikit/icons/icons.component";
import {CommonModule} from "@angular/common";
import {SessionService} from "../../service/session.service";
import {Router, RouterModule} from "@angular/router";
import {from, map, Observable, take} from "rxjs";
import {SubscriptionsService} from "../../service/subscriptions.service";
import {DialogComponent} from "../../uikit/dialog/dialog.component";
import {SubscriberListComponent} from "../subscriber-list/subscriber-list.component";
import {UserShortModelData} from "@prestinly/core-lib/lib/types/users";
import {ApiService} from "../../api/api.service";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-user-popup',
  standalone: true,
  imports: [
    ProfileAvatarComponent,
    IconsComponent,
    CommonModule,
    RouterModule,
    DialogComponent,
    SubscriberListComponent,
    TranslocoModule
  ],
  templateUrl: './user-popup.component.html',
  styleUrl: './user-popup.component.scss',
  providers: [
    SessionService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPopupComponent {
  private apiService = inject(ApiService);
  @Input() user: User;
  @ViewChild('statsDialogRef') statsDialogRef: DialogComponent | null = null;
  isOpen = false;
  public subList: UserShortModelData[] = [];

  constructor(private session: SessionService,
              private subscriptionsService: SubscriptionsService,
              private router: Router,
              private crd: ChangeDetectorRef) {
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!(event.target as HTMLElement).closest('.popup-menu-container')) {
      this.isOpen = false;
    }
  }

  logout(): void {
    this.session.logout();
  }

  loadSubscribesList(): Observable<any> {
    return this.subscriptionsService.getSubscribes(this.user.id)
      .pipe(
        take(1),
        map(data => data.map(item => item.user))
      );
  }

  loadSubscribersList(): Observable<any> {
    return this.subscriptionsService.getSubscribers(this.user.id)
      .pipe(
        take(1),
        map(data => data.map(item => item.user))
      );
  }

  showFollows() {
    this.loadSubscribesList().subscribe((data) => {
      this.subList = data;
      this.crd.detectChanges();
      if (this.subList.length) {
        this.toggleMenu();
        this.statsDialogRef?.open();
      }
    });
  }

  showFollowing() {
    this.loadSubscribersList().subscribe((data) => {
      this.subList = data;
      this.crd.detectChanges();
      if (this.subList.length) {
        this.toggleMenu();
        this.statsDialogRef?.open();
      }
    });
  }

  public routeToProfile(event: UserShortModelData): void {
    this.statsDialogRef.close();
    from(this.apiService.getUserById(event.id)).subscribe(
      (data) => {
        if (data.isCreator) {
          this.router.navigate([`/profile/${event.id}`]);
        }
      }
    );
  }
}
