import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { User } from 'src/app/model/user';
import { SessionService } from 'src/app/service/session.service';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { ProfileAvatarComponent } from 'src/app/uikit/profile/profile-avatar/profile-avatar.component';
import { ProfileStatsComponent } from 'src/app/uikit/profile/profile-stats/profile-stats.component';
import {TranslocoModule} from "@ngneat/transloco";

@Component({
  selector: 'app-users-subnavigation',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    ProfileStatsComponent,
    IconsComponent,
    RouterModule,
    TranslocoModule
  ],
  providers: [
    SessionService,
  ],
  templateUrl: './users-subnavigation.component.html',
  styleUrl: './users-subnavigation.component.scss'
})
export class UsersSubnavigationComponent {
  public user;
  @Input() avatarUrl: string;
  @Input() set userData(value: User){
    this.user = value;
    this.setNavigationsLink();
  };
  @Output() subNavStatus = new EventEmitter<null>();
  @Output() obBecomeCreatorEvent = new EventEmitter<null>();
  public navigationLinkList;


  constructor(private session: SessionService){}

  public logout(): void {
    this.hideSubMenu();
    this.session.logout();
  }

  public hideSubMenu(): void {
    this.subNavStatus.emit(null);
  }

  public becomeCreatorPress(): void {
    this.hideSubMenu();
    this.obBecomeCreatorEvent.emit(null)
  }

  private setNavigationsLink(): void {
    this.navigationLinkList = [
      {
        name: 'My-profile',
        routerLink: '/my-profile',
        icon: 'profile',
        visible: this.user?.isCreator || false
      },
      {
        name: 'Queue',
        routerLink: '/queue',
        icon: 'calendar_2',
        visible: this.user?.isCreator || false
      },
      {
        name: 'Statements',
        routerLink: '/statements',
        icon: 'statements',
        visible: this.user?.isCreator || false
      },
      {
        name: 'Content Hub',
        routerLink: '/content',
        icon: 'content',
        visible: this.user?.isCreator || false
      },
      {
        name: 'Settings',
        routerLink: '/settings',
        icon: 'settings',
        visible: true
      },
      {
        name: 'Boost your Account',
        routerLink: 'https://agency.desiren.me/',
        icon: 'boost',
        visible: this.user?.isCreator || false,
        isBlank: true
      },

    ];
  }

}
