<ng-container>
  <div class="submenu" *ngIf="user">
    <div class="submenu-user">
      <app-profile-avatar [url]="avatarUrl" [size]="'chat'"></app-profile-avatar>
      <span class="submenu-user-name">
        <span class="submenu-user-displayname">{{user.creatorDetails?.displayName || user.nickname}} </span>
        <span class="submenu-user-nickname" *ngIf="user.creatorDetails?.displayName">{{'@' + user.nickname }} </span>
      </span>
      <app-profile-stats
        [followers]="user.countFollowers"
        [follows]="user.countFollows"
        [posts]="user.countPosts"
        [isCreator]="user.isCreator"
        [isMyProfile]="true"
    ></app-profile-stats>
    </div>
    <div class="become-menu-item navigation-list-item" id="become-a-creator-btn" *ngIf="!user.isCreator" (click)="becomeCreatorPress()">
      <app-icons name="fire" [width]="28" [height]="28" fillColor="#6A49FA"></app-icons>
      <span *transloco="let t;">{{t('menu_Become_a_Creator')}}</span>
    </div>
    <ul class="submenu-list">
      <li (click)="hideSubMenu()" class="submenu-item" *ngFor="let item of navigationLinkList" [ngClass]="{'hidden': !item.visible}">
        <button *ngIf="item.visible && !item.isBlank" class="navigation-list-item" routerLinkActive="navigation-list-item-active" [routerLink]="item.routerLink">
          <app-icons name="{{item.icon}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
          <span class="navigation-list-item-title" *transloco="let t;">{{t(item.name)}}</span>
        </button>
        <a *ngIf="item.visible && item.isBlank" class="navigation-list-item" routerLinkActive="navigation-list-item-active" href="{{item.routerLink}}" target="_blank">
          <app-icons name="{{item.icon}}" [width]="28" [height]="28" fillColor="#131315"></app-icons>
          <span class="navigation-list-item-title" *transloco="let t;">{{t(item.name)}}</span>
        </a>
      </li>
      <li (click)="hideSubMenu()" class="submenu-item" *transloco="let t;">
        <button class="navigation-list-item" (click)="logout()">
          <app-icons name="logout" [width]="28" [height]="28" fillColor="#131315"></app-icons>
          <span class="navigation-list-item-title">{{t('logout')}}</span>
        </button>
      </li>
    </ul>
  </div>
</ng-container>
